import * as React from "react"
import Layout from "../components/layout"
// import Seo from "../components/seo"
import Seo2 from "../components/Seo2"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import PageHeader from '../components/PageHeader'
import CoverImage from '../components/CoverImage'
import Container from '../components/Container'
import FaqItems from '../components/FaqItems'
import PreFooter from '../components/PreFooter'
import Button from '../components/Button'

const FaqPage = () => (
  <Layout>
    {/* <Seo title="FAQ" /> */}
    <CoverImage crop={true}>
			<StaticImage

				src={`../images/stock/casak9-ZXsB4DG9cY.jpg`}
				alt="faq banner image"
        loading={`eager`}
				/>
		</CoverImage>
    <PageHeader
    pageTitle="Frequently <br /> Asked Questions"
    preheader="You asked, we answered"
    className="-mt-10 max-w-2xl mx-auto text-center">
      <p className="mt-5 mb-0">
      Can't find the answer you're looking for? Don't hesitate to <Link to={`/contact`} className="text-primary-default hover:text-primary-darker border-b border-primary-default hover:border-primary-darker">contact us</Link>.
      </p>
    </PageHeader>
    <Container size="medium">
    	<FaqItems className={`p-2 sm:p-5 md:p-8 md:py-5 lg:px-16 lg:py-8 xl:px-20 xl:py-10`} />

    </Container>
    <PreFooter className="mt-20  text-center text-white" bgClass="mt-20 bg-primary-darker" header="Meet the Team"
    content="We have a long history of serving the Greater Ottawa area."
    >
        <Button to={`/team`} variation="outline" invert={true}>About Us</Button>
    </PreFooter>
  </Layout>
)

export const Head = () => <Seo2 title={`FAQ`} />

export default FaqPage
