import React from 'react'
import PropTypes from 'prop-types'
import {  coverImage, pageImage } from '../styles/media.module.scss'

const CoverImage = ({ crop, children }) => {

	let coverImageClassName = 'w-full mb-10';
	if (crop === true) coverImageClassName = ['w-full mb-10', coverImage, pageImage].join(" ");

	return (

		<div className={coverImageClassName}>

		{children}

		</div>
	)
}

CoverImage.propTypes = {
	children: PropTypes.node.isRequired,
}

CoverImage.defaultProps = {
	crop: false
}

export default CoverImage
