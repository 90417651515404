import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import Container from './Container'
import CoverImage from './CoverImage'
import { useMediaQuery } from 'react-responsive'

const PageHeader = ( {pageTitle, preheader, description, coverImage, children, containTitle, ...rest } ) => {

	const aboveMd = useMediaQuery({ query: '(min-width: 768px)' })

	const hgroupStyle = {
		boxShadow: (coverImage && aboveMd) ? 'rgba(149, 157, 165, 0.2) 0px -54px 24px -4px' : 'none',
	}

	let hgroupClassName = null;
	if (coverImage) hgroupClassName = 'md:-mt-72 lg:-mt-[20rem] md:mb-auto relative z-40';


	return (

		<div className={`pageHeader w-full ${!coverImage ? 'mb-10 md:mb-20 pt-10' : ''}`}>
		<div {...rest}>

			{coverImage &&

				<CoverImage crop={true}>
					<GatsbyImage alt={`${pageTitle} cover image`} image={getImage(coverImage)}
					style={{clipPath: `${aboveMd ? 'polygon(0 0, 100% 0%, 100% 100%, 0 78%)' : 'polygon(0 0, 100% 0%, 100% 100%, 0 90%)'}`}}
					loading={`eager`}
					/>
				</CoverImage>
			}
			<Container>

				<hgroup className={hgroupClassName}>
					<div className={`${coverImage ? 'p-10 lg:p-16 lg:pb-0 lg:pt-12 lg:max-w-2xl bg-background inline-block md:rounded-tr-sm md:rounded-tl-sm' : 'block'}`} style={hgroupStyle}>
					{preheader &&
						<h6
						dangerouslySetInnerHTML={{__html: preheader}}
						className="mb-3 block text-primary-default"
						/>
					}
					<h1
					dangerouslySetInnerHTML={{__html: pageTitle}}
					className={`lg:text-[3.75rem] md:text-4xl lg:-tracking-[0.1rem] -tracking-[0.05rem] mb-3 font-bold ${containTitle === true ? 'md:mx-auto md:w-[60%] md:min-w-[25rem]' : 'w-full'}`}
					/>
					<p
					dangerouslySetInnerHTML={{__html: description}}
					className="mb-0 font-medium"
					/>

					{children &&

						children
					}

					</div>
				</hgroup>

			</Container>
		</div>

		</div>
	)
}

PageHeader.propTypes = {
	pageTitle: PropTypes.string,
	description: PropTypes.string,
	coverImage: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	  ]),
	children: PropTypes.node,
}

PageHeader.defaultProps = {
	pageTitle: ``,
	description: ``,
	coverImage: ``,
	containTitle: true
}

export default PageHeader
